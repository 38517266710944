main {
  min-height: 80vh;
  background-color: white;
  /* background-color: rgba(240, 241, 243, 0.959); */
  /* background-color: rgba(249, 251, 253, 0.932); */
}

h3 {
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.1rem;
}

h1 {
  font-size: 1.8rem;
  padding: 0.5rem 0;
}

.h55 {
  font-size: 1.2rem;
  font-weight: bold;
}

.h55light {
  font-size: 1rem;
}

.h6 {
  font-size: 1rem;
  font-weight: bold;
}

.h7 {
  font-size: 0.8rem;
  font-weight: bold;
}

.h8 {
  font-size: 0.6rem;
  font-weight: bold;
}

.datetime {
  font-size: 0.7rem;
  font-weight: bold;
}

.gradient-background {
  /* background: linear-gradient(to right, #0f4dd2 50%, #aa074e 50%); */
  background: white;
  border-bottom:'solid';
  border-color:'gray';
}

.header {
  /* background-color: #fcdbbf; */
/* 
  background-color: #d7e6f0 !important; */
  /* background-color: white !important; */

  /* background-color: #174663; */

  /* #ebaf7c; */
  /* #1a5276; */
  padding: -10px;
}

.footer {
  /* background-color: #fcdbbf; */

  /* background-color: #d7e6f0 !important; */
  background-color: white !important;

  /* background-color: #f4a460; */
  color: black;
}

.rating_span {
  margin: 0.1rem;
}

.button_gen {
  background-color: #00416a;
  /* #00416a; */
  /* #1a5276; */
  /* #ff9933; */
  /* #1a5276; */
}


img {
  padding: 10px;
}

.tylogo {
  padding: -10px;
  margin: -10px;
  position: relative;
  top: -10px;
}

.navbarButton {
  background-color: white;
  color: #174663;
}

.trialColor {
  background-color: rgb(253, 245, 239);
}

.navbarlink {
  /* color: white !important; */
  /* color: black !important; */
  color: #00416a !important;
  border-style: "solid";
}

.nav a {
  color: black !important;
}


.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h2 {
  color: #fff;
}

.carousel img {
  height: 300px;
  padding: 30px;
  margin: 40px;
  border-radius: 50%;
}

.carousel a {
  margin: 0 auto;
}



.puzzle_rows{
  margin:'0px' !important;
  padding:'0px' !important;
}

.puzzle_columns{
  margin:'0px' !important;
  padding:'0px' !important;
}

tr td{
  padding: 0 !important;
  margin: 0 !important;
}

.keyboardP {
  border-radius:10px; 
  border:2px solid #8b008b; 
  background-color:#f0f0f5;
  color:black;
  margin: 1px;
  padding:1.5vh;
  padding-left:2.0vh;
  padding-right:2.0vh;
  

}


.keyboardPMob {
  border-radius:10px; 
  border:2px solid #8b008b; 
  background-color:#f0f0f5;
  color:black;
  margin: 0px;
  padding:1.5vw;
  padding-left:3vw;
  padding-right:3vw;
  

}

.gridP {
  text-align:center;
  width:33%;
  padding-bottom: 33%;
  height:0;
  left:1vw;
}


.correctTextColor {
  /* color: #796808; */
  /* color: #087b08; */
  color: #075d2b;
}

.tyzlleCarouselColor {
  color: #42075d;
}




.tablestyle {

  border-collapse: collapse;
  border-spacing: 0;
  /* width: 100%; */
  /* border: 1px solid #ddd; */
  /* overflow-x:auto; */
}

/* th, td {
  text-align: left;
  padding: 8px;
  margin:10px;
} */

/* td {
  width: 10%;
  min-width:50px;
  font-weight: 600;
} */


.about-us-container {
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.about-header {
  background-color: #4CAF50;
  color: white;
  text-align: center;
  padding: 20px 0;
}

.about-intro, .about-mission, .about-features, .about-team {
  margin-bottom: 40px;
}

.about-team .team-members {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.team-member {
  width: calc(25% - 20px); /* Adjust based on your design */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.team-member img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.about-footer {
  text-align: center;
  padding: 20px 0;
  background-color: #f4f4f4;
}



/* Virtual Keyboard css */

.virtual-keyboard {
  margin-left:5px;
  margin-right:5px;
  width: 100vw;
  max-width: 100%; /* Prevents the keyboard from exceeding the screen width */
  padding: 5px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keyboard-row {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 2px 0;
}

.virtual-key {
  width: 9vw; /* Adjust based on screen width, ensuring keys scale down on smaller screens */
  height: 6vh; /* Adjust height based on screen height for better touch targets */
  margin: 0 1px; /* Small gap between keys */
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  border-color:#8b008b;
  font-size: 1.5vh; /* Adjust font size based on screen height */
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.virtual-key:active {
  background-color: #e0e0e0;
}

/* Specific adjustments for special keys like backspace and enter */
.special-key {
  width: 10vw; /* Special keys might be slightly wider */
}

@media (max-width: 768px) {
  .virtual-key, .special-key {
    width: 10vw; /* Increase width for smaller screens to maintain usability */
    height: 8vh; /* Increase height for better touch targets on smaller screens */
    font-size: 2vh; /* Increase font size for better visibility */
  }
}